export default {
	name: 'SignContract',
	data() {
		return {
			btnAvilable: true,
			leftTime: 60,
			agree: false,
			contract: [
				{
					item_title: '第 一 章 总 则',
					cluse: [{
						item_cluse: '第一条 ',
						item_text: '本项目的名称是“临床研究协调员进修-管理-评估平台”，简称“CRC平台”项目。'
					}, {
						item_cluse: '第二条 ',
						item_text: '项目性质：CRC 平台是在中国抗癌协会领导下、由该会继续教育与科技服务部组织管理的，国内临床试验机构和 SMO 公司自愿参与的临床研究协调员（简称 CRC）进修-管理-评估一体化平台。'
					}, {
						item_cluse: '第三条',
						item_text: '项目宗旨：遵守中国相关法律，遵守中国抗癌协会章程及相关管理规定，以中国药物/医疗器械相关法律、法规和指导原则为依据，结合ICH-GCP、赫尔辛基宣言等相关领域国际规范，开展 CRC 培训、管理、评估和资质认证，提高 CRC 人员整体素质与从业水平。',
					}, {
						item_cluse: '第四条',
						item_text: '项目任务：“CRC 平台”项目将为中国 CRC 从业人员搭建：①系统培训与试验机构现场实习的高水平进修平台，②智能、便捷的医院端日常工作管理平台，以及③多维度、主客观并举的权威评估平台。',
					}, {
						item_cluse: '第五条',
						item_text: '“CRC 平台”项目办公室设于中国抗癌协会总部所在地天津市。',
					}],
				}, {
					item_title: '第二章 业务范围',
					cluse: [{
						item_cluse: '第六条 ',
						item_text: 'CRC 培训业务',
						item__text_sub: [
							'（一）组织 CRC 的分级进修培训：包括临床试验法律、法规和指导原则学习，GCP 相关知识高级培训，临床试验操作技能现场实践，临床试验相关专业知识专家现场授课等。',
							'（二）建设 CRC 进修培训基地：在有条件的临床试验机构设立 CRC 进修培训基地，资助其师资队伍建设，免费提供规范培训教材和现场实践指导。评估合格的机构将授予“中国抗癌协会 CRC 进修培训基地” 资格。',
							'（三）CRC 的考核及资质认证：为经过培训的 CRC 提供资质认证及专业等级考核，考核通过后颁发相应证书并登记备案。'
						],
					}, {
						item_cluse: '第七条',
						item_text: 'CRC 管理业务',
						item__text_sub: [
							'（一）CRC 的持续管理：总结平台参加机构及 SMO 公司管理经验，推动 CRC 日常管理工作信息化，提升临床试验机构对 CRC 工作的支持和管理能力，形成 CRC 工作量客观评价指标。',
							'（二）搭建多方共享的信息平台，实现试验机构、SMO 公司和申办方之间的有效沟通，及时了解 CRC 工作情况，便于各方实施管理。'
						],
					}, {
						item_cluse: '第八条',
						item_text: 'CRC 评估业务',
						item__text_sub: [
							'（一）建立由临床试验各相关方共同参与的 CRC 行业管理与评估体系，由研究者、机构管理人员、申办方、带教老师多方面参与评估，保证评估的可靠性。',
							'（二）通过信息化系统填写评估问卷，保证评估过程与内容的规范性。',
							'（三）由权威行业协会提供的评估结果，为研究者与申办方遴选 CRC 提供参考依据，体现项目的实用性。'
						],
					}, {
						item_cluse: '第九条',
						item_text: 'CRC 培训-管理-评估信息系统开发：为 CRC 在医疗机构开展工作提供高效便捷的电子化工具，为医疗机构提供 CRC 管理与评估信息系统，为 SMO 公司精准管理和申办方恰当选择 CRC 提供科学依据。',
					}],
				}, {
					item_title: '第三章 成员单位',
					cluse: [{
						item_cluse: '第十条',
						item_text: '本平台仅接受单位成员的加入申请。'
					}, {
						item_cluse: '',
						item_text: '第十一条 申请加入本平台的单位，必须具备下列条件：',
						item_text_sub: [
							'一）拥护本平台项目的章程；',
							'（二）有加入本平台项目的意愿；',
							'（三）在本平台项目的业务领域内具有一定的影响。',
						],
					}, {
						item_cluse: '第十二条',
						item_text: '单位成员的申请程序：',
						item_text_sub: [
							'（一）提交入会申请书；',
							'（二）经平台管理委员会讨论通过；',
							'（三）由中国抗癌协会继续教育与科技服务部发给成员单位证书。'
						],
					}, {
						item_cluse: '第十三条',
						item_text: '成员单位享有下列权利：',
						item_text_sub: [
							'（一）参加本平台的活动；',
							'（二）获得本平台服务的优先权；',
							'（三）对本平台工作的批评建议权和监督权；',
							'（四）参加自愿，退出自由。'
						],
					}, {
						item_cluse: '第十四条',
						item_text: '平台单位履行下列义务：',
						item_text_sub: [
							'（一）遵守本平台的章程；',
							'（二）执行本平台的决议；',
							'（三）维护本平台合法权益；',
							'（四）履行与本平台签署的合作协议。'
						],
					}, {
						item_cluse: '第十五条',
						item_text: '成员单位退出本平台应书面通知并交回成员单位证书。',
						item_text_sub: [
							'（一）成员单位如果长期（1 年及以上）不能履行对本平台承诺的义务，经平台管理委员会确认，视为自动丧失成员单位资格；',
							'（二）成员单位如有严重违反法律法规和本章程的行为，经平台管理委员会表决通过予以除名。',
							'（三）成员单位退回、自动丧失会员资格或被除名后，其在本团体相应的职务、权利、义务自行终止。',
						],
					}],
				}, {
					item_title: '第四章 平台管理委员会',
					cluse: [{
						item_cluse: '第十六条',
						item_text: '本平台的最高管理机构是平台管理委员会，其职权是：',
						item__text_sub: [
							'（一）制定和修改章程；',
							'（二）选举和免去委员；',
							'（三）审议平台工作报告；',
							'（四）制定和修改平台发展方向；',
							'（五）决定其他重大事宜。'
						],
					}, {
						item_cluse: '第十七条',
						item_text: '平台管理委员会须有 2/3 以上的委员出席方能召开，其决议须经到会委员半数以上表决通过方能生效。'
					}, {
						item_cluse: '第十八条',
						item_text: '平台管理委员会每届 1 年，每年召开 1 次。因特殊情况需提前或延期换届的，须报中国抗癌协会批准同意，但延期换届最长不超过 1 年。'
					}],
				}, {
					item_title: '第五章 章程的修改程序',
					cluse: [{
						item_cluse: '第十九条',
						item_text: '对本平台章程的修改，须经平台管理委员会表决通过。'
					}, {
						item_cluse: '第二十条',
						item_text: '本平台修改的章程，须在平台管理委员会表决通过后的 15 个工作日内，经中国抗癌协会审查同意，批准后生效。'
					}],
				}, {
					item_title: '第六章 终止程序',
					cluse: [{
						item_cluse: '第二十一条',
						item_text: '本平台终止动议由平台管理委员会提出，经表决通过，并报中国抗癌协会审查同意。'
					}, {
						item_cluse: '第二十二条',
						item_text: '中国抗癌协会有权根据业务需要或上级部门要求终止平台。'
					}],
				}, {
					item_title: '第 七 章 附 则',
					cluse: [{
						item_cluse: '第二十三条',
						item_text: '本章程经第一届中国抗癌协会临床研究协调员进修-管理-评估平台管理委员会表决通过。'
					}, {
						item_cluse: '第二十四条',
						item_text: '本章程的解释权属本平台管理委员会。第二十五条 本章程自中国抗癌协会核准之日起生效。'
					}],
				},
			]
		};
	},
	methods: {
		back: function () {
			//this.$router.back
		},
		agreeClick: function () {
			this.$emit('agreeClick')
		}
	},
	created() {
//		let timer;
//		let leftTime = 60;
//		timer = setInterval(() => {
//			this.leftTime = leftTime--;
//			if (this.leftTime < 0) {
//				clearInterval(timer);
//				this.btnAvilable = true;
//			}
//		}, 1000);
	}
}
