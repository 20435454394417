import { REQP } from '../../services/config'

import Cookie from "js-cookie";
import { RegistService } from "@/services";
import fftData from "@/views/regist/util";
import { UploadUrl } from "@/services/config";
import Contract from "@/components/sign/Contract.vue";
import { pca, pcaa } from 'area-data'; // v5 or higher
import PubSub from "@/lib/Pub";
export default {
	name: "SignCompony",
	components: {
		Contract
	},
	data() {
		return {
			pca,
			pcaa,
			showLoading: false,
			showContract: false,
			httpHeader: {
				token: Cookie.get("token")
			},
			UploadUrl,
			formData: {
				sname: "",
				name: "",
				type: null,
			},
			rules: {
				sname: [{ required: true, message: "必填", trigger: "blur" }],
				name: [{ required: true, message: "必填", trigger: "blur" }],
				type: [{ required: true, message: "必选", trigger: "blur" }],
			}
		};
	},
	async created() {
		// this.showLoading = true;
		// let res = await RegistService.getSignInfo(2);
		// this.showLoading = false;
		// if (res.code === 200) {
		// 	this.formData = fftData(res.data, this.formData);
		// } else if (res.code === 401) {
		// 	PubSub.publish("showLoginEvent");
		// 	return
		// } else {
		// 	this.$message("服务器异常，请稍后重试！");
		// }
	},
	methods: {
		nextStep(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.showContract = true;
				} else {
					return false;
				}
			});
		},

		async submit() {
		    console.log('submit')
            this.authedReq(REQP.regOrg, this.formData, data => {
            	this.$router.push("/myDocPrjts");
            })
		},
	}
};
